<template>
  <div>
    <w-top-bar>Retiros en Tienda</w-top-bar>
    <w-pickup-confirm
      v-if="showConfirm"
      @hide="showConfirm = false"
      v-model="selected"
    />

    <div class="h-full p-5 bg-gray-200">
      <div class="grid grid-cols-2 justify-items-stretch">
        <div class="flex gap-2 justify-self-start">
          <w-switch
            label="Ocultar entregados"
            v-model="hideDelivered"
          ></w-switch>
        </div>
        <div class="justify-self-end">
          <w-date-range v-model="dateFilter" @change="reload()" />
        </div>
      </div>
      <div class="relative pt-2 mx-auto mb-2 text-gray-600">
        <input
          class="w-full h-10 px-5 pr-16 text-sm bg-white border-2 border-gray-300 rounded-lg focus:outline-none"
          type="search"
          v-model="searchTerm"
          v-debounce:1000ms="doSearch"
          name="search"
          placeholder="Buscar por nombre, apellido, productos, correo electrónico o ID de Pedido"
        />
        <button
          @click="doSearch()"
          type="submit"
          class="absolute top-0 right-0 mt-5 mr-4"
        >
          <svg
            class="w-4 h-4 text-gray-600 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            viewBox="0 0 56.966 56.966"
            style="enable-background: new 0 0 56.966 56.966"
            xml:space="preserve"
            width="512px"
            height="512px"
          >
            <path
              d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"
            />
          </svg>
        </button>
      </div>

      <div class="relative">
        <w-loading
          class="absolute inset-center-mx mt-10"
          v-if="loading"
        ></w-loading>
        <w-pickups
          :class="{ 'opacity-25': loading }"
          @deliver="doDeliver"
          @edit="doDeliver"
          :totalResults="totalResults"
          :patch-pending="false"
          :find-pending="false"
          :loading="loading"
          :items="shipments"
        />
        <w-pagination
          v-if="totalResults > 0"
          @pageChanged="currentPage = $event"
          :totalResults="totalResults"
          v-model="currentPage"
          :resultsPerPage="limit"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import _ from "lodash";
// import { makeFindMixin } from "feathers-vuex";
import { mapActions, mapMutations } from "vuex";
import moment from "moment";
// Importar el feathers client
export default {
  name: "Retiros en Tienda",
  data() {
    return {
      hideDelivered: true,
      searchTerm: null,
      selected: null,
      showConfirm: false,
      shipments: [],
      filter: "default",
      dateFilter: {
        start: moment().subtract(20, "days").startOf("day").toDate(),
        end: moment().subtract(0, "days").endOf("day").toDate(),
      },
      loading: false,
      currentPage: 1,
      limit: 20,
      skip: 0,
      totalResults: 0,
    };
  },
  watch: {
    orderId() {
      this.loadMore();
    },
    query: {
      deep: true,
      handler() {
        console.log("query Changed");
        this.currentPage = 1;
        this.loadMore();
      },
    },
    currentPage() {
      this.loadMore();
    },
    hideDelivered() {
      this.loadMore();
    },
    dateFilter() {
      this.currentPage = 1;
      this.clearAll();
      this.findShipments();
    },
  },
  computed: {
    scanQrItems() {
      return this.$store.state["scan-qr"].items;
    },
    orderId() {
      return this.$route.query.orderId;
    },
    query() {
      console.log("Generating Query", this.filter, this.filterShipping);

      let query = {
        $limit: this.limit,
        createdAt: {
          $gt: moment(this.dateFilter.start).unix(),
          $lt: moment(this.dateFilter.end).unix(),
        },
      };

      query["shippingMethodId"] = "5fe12660d0f5b5f4fc6efa37";
      return query;
    },
  },
  async created() {
    if (this.$route.query.q && this.$route.query.q.length > 1) {
      this.filter = this.$route.query.q;
    }

    this.$FeathersVuex.api.ScanQr.on("created", (data) => {
      const idUsr = data.idUsr;

      console.log("ScanQr created", data.idUsr, idUsr);

      if (!this.$store.state.auth.user) {
        console.log("No auth");
        return;
      }

      // Si el usuario es el mismo que el logueado, se redirige a la página de retiros
      if (idUsr === this.$store.state.auth.user._id) {
        // Si esta en la pagina de retiros, no redirigir, recargar la pagina con el query param
        if (this.$route.name === "retiros") {
          this.searchTerm = data.orderId;
          this.loadMore();
        }
      }
    });
  },
  async mounted() {
    this.loadMore();

    // Crear scan-qr
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    doSearch() {
      this.loadMore();
    },
    async loadMore() {
      this.loading = true;
      let query = this.query;
      if (this.searchTerm && this.searchTerm.length > 0) {
        // Verificar si el search term es un ID de pedido (7356-AAHE) o (1649-141799-3287)
        const isOrderId =
          this.searchTerm.match(/^([0-9]{4}-[0-9]{6}-[0-9]{4})$/) ||
          this.searchTerm.match(/^([0-9]{4}-[A-Z]{4})$/);

        if (isOrderId) {
          console.log("isOrderId", isOrderId);
          query = {
            shippingMethodId: "5fe12660d0f5b5f4fc6efa37",
            orderIdCode: this.searchTerm,
          };
        } else {
          query = {
            shippingMethodId: "5fe12660d0f5b5f4fc6efa37",
            $search: this.searchTerm,
          };
        }
      }

      if (this.hideDelivered) {
        query["delivered"] = { $ne: true };
      }

      console.log("this.$route.query.orderId", this.$route.query.orderId);

      if (this.$route.query.orderId) {
        query = {
          shippingMethodId: "5fe12660d0f5b5f4fc6efa37",
          orderId: this.$route.query.orderId,
        };
      }
      console.log("Query", query);

      const result = await this.findShipments({
        query: {
          ...query,
          $limit: this.limit,
          $skip: this.limit * (this.currentPage - 1),
          $sort: { createdAt: -1 },
        },
      });
      this.shipments = result.data;
      this.totalResults = result.total;
      this.loading = false;

      if (this.$route.query.orderId) {
        this.doDeliver(this.shipments[0]);
      }
    },
    ...mapActions("shipments", { patch: "patch", findShipments: "find" }),
    ...mapMutations("shipments", ["clearAll"]),
    updateFilter() {
      if (this.filter || this.filterShipping) {
        this.$router.push({
          path: "shipments",
          query: { q: this.filter, szId: this.filterShipping },
        });
        this.clearAll();
        this.findShipments();
      }
    },
    objectToFormData(obj, form, namespace) {
      var fd = form || {};
      var formKey;

      for (var property in obj) {
        if (namespace) {
          formKey = namespace + "[" + property + "]";
        } else {
          formKey = property;
        }

        // if the property is an object, but not a File,
        // use recursivity.
        if (typeof obj[property] === "object") {
          this.objectToFormData(obj[property], fd, property);
        } else {
          // if it's a string or a File object
          // fd.push(`${formKey}=${obj[property]}`);
          fd[formKey] = obj[property];
        }
      }

      return fd;
    },
    async doDeliver(data) {
      this.selected = data;
      this.showConfirm = true;
    },
    reload() {
      console.log("reload");
      this.status = "clear";
      this.findShipments();
    },
  },
};
</script>

<style lang="scss" scoped>
.inset-center-mx {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}
</style>
