import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1ee18dfa&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=1ee18dfa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ee18dfa",
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/workspace/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import WDateRange from '@/components/WDateRange.vue'
import WLoading from '@/components/WLoading.vue'
import WPagination from '@/components/WPagination.vue'
import WPickupConfirm from '@/components/WPickupConfirm.vue'
import WPickups from '@/components/WPickups.vue'
import WSwitch from '@/components/WSwitch.vue'
import WTopBar from '@/components/WTopBar.vue'
autoInstallComponents(component, {WDateRange,WLoading,WPagination,WPickupConfirm,WPickups,WSwitch,WTopBar})
