<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="
        flex
        items-end
        justify-center
        min-h-screen
        pt-4
        px-4
        pb-20
        text-center
        sm:block sm:p-0
      "
    >
      <!--
        Background overlay, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100"
          To: "opacity-0"
      -->
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >

      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <div
        class="
          inline-block
          align-bottom
          bg-white
          rounded-lg
          text-left
          overflow-hidden
          shadow-xl
          transform
          transition-all
          sm:my-8 sm:align-middle sm:max-w-lg sm:w-full
        "
      >
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <!-- <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </div> -->
            <div
              class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex-grow"
            >
              <h3
                class="text-lg leading-6 font-medium text-gray-900"
                id="modal-title"
              >
                Confirmar entrega a {{ buyer }}
              </h3>
              <div class="mt-2 w-full">
                <w-loading
                  v-if="loader"
                  :label="loader === 'processing' ? 'Guardando datos' : null"
                  class="w-full"
                />
                <div v-else>
                  <div
                    class="
                      flex flex-col
                      py-4
                      border-b border-dashed border-gray-400
                      mb-4
                    "
                  >
                    <div class="w-full">
                      <div v-if="value" class="border border-gray-300 rounded">
                        <table>
                          <thead class="bg-gray-50 border-b">
                            <tr>
                              <th
                                class="text-center py-2 text-xs text-gray-500"
                              >
                                Cant
                              </th>
                              <th class="px-6 py-2 text-xs text-gray-500">
                                Producto
                              </th>
                              <th
                                class="
                                  px-6
                                  py-2
                                  text-xs text-gray-500 text-right
                                "
                              >
                                SKU
                              </th>
                            </tr>
                          </thead>
                          <tbody
                            v-for="item in value.items"
                            :key="item._id"
                            class="bg-white"
                          >
                            <tr class="whitespace">
                              <td
                                class="
                                  px-6
                                  py-4
                                  text-sm text-gray-900 text-center
                                "
                              >
                                {{ item.qty }}
                              </td>
                              <td class="px-6 py-4 w-3/4">
                                <div class="text-sm text-gray-900">
                                  {{ item.name }}
                                </div>
                              </td>
                              <td class="px-6 py-4 w-1/4 text-right">
                                <div class="text-sm text-gray-900">
                                  {{ item.sku }}
                                </div>
                                <span
                                  v-for="(
                                    variant, indexVariant
                                  ) in item.selectedVariant"
                                  :key="indexVariant"
                                  class="
                                    bg-green-800
                                    text-white
                                    p-1
                                    rounded
                                    text-xs
                                  "
                                >
                                  {{ variant.name }}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div
                          v-if="value.customValues"
                          class="
                            bg-indigo-100
                            rounded-b
                            p-2
                            text-xs text-gray-800
                          "
                        >
                          <div
                            v-for="(
                              custom, indexCustom
                            ) in procesarCustomValues(value.customValues)"
                            :key="indexCustom"
                          >
                            {{ custom }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <w-input
                    class="mt-3"
                    description="Nombre de la persona que retira la compra"
                    label="¿Quién retira la compra?"
                    v-model="to"
                  />
                  <w-input
                    class="mt-3"
                    description="Nombre de la persona del staff"
                    label="¿Quién realiza la entrega?"
                    v-model="by"
                  />
                  <w-textarea
                    class="mt-3"
                    description="¿Querés agregar alguna observación?"
                    label="Notas extra"
                    v-model="notes"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="value.deliveredData">
          <div
            v-if="modify"
            class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
          >
            <button
              @click="save()"
              :disabled="!isValid"
              :class="{
                'bg-gray-500': !isValid,
                'bg-indigo-600': isValid,
              }"
              type="button"
              class="
                w-full
                inline-flex
                justify-center
                rounded-md
                border border-transparent
                shadow-sm
                px-4
                py-2
                text-base
                font-medium
                text-white
                hover:bg-indigo-700
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-red-500
                sm:ml-3 sm:w-auto sm:text-sm
              "
            >
              Guardar entrega
            </button>
            <button
              @click="hideModal()"
              type="button"
              class="
                mt-3
                w-full
                inline-flex
                justify-center
                rounded-md
                border border-gray-300
                shadow-sm
                px-4
                py-2
                bg-white
                text-base
                font-medium
                text-gray-700
                hover:bg-gray-50
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-indigo-500
                sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm
              "
            >
              Cancelar
            </button>
          </div>
          <div
            v-else
            class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex justify-between"
          >
            <!-- Checkbox to edit this record -->
            <label
              class="flex items-center text-xs text-indigo-500 cursor-pointer"
            >
              <input
                value="sent"
                v-model="modify"
                type="checkbox"
                class="
                  w-4
                  h-4
                  text-indigo-600
                  border-gray-300
                  rounded
                  focus:ring-indigo-500
                  mr-1
                "
              />
              Editar entrega
            </label>
            <button
              @click="hideModal()"
              type="button"
              class="
                mt-3
                w-full
                inline-flex
                justify-center
                rounded-md
                border border-gray-300
                shadow-sm
                px-4
                py-2
                bg-white
                text-base
                font-medium
                text-gray-700
                hover:bg-gray-50
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-indigo-500
                sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm
              "
            >
              Cerrar
            </button>
          </div>
        </div>
        <div
          v-else
          class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
        >
          <button
            @click="save()"
            :disabled="!isValid"
            :class="{
              'bg-gray-500': !isValid,
              'bg-indigo-600': isValid,
            }"
            type="button"
            class="
              w-full
              inline-flex
              justify-center
              rounded-md
              border border-transparent
              shadow-sm
              px-4
              py-2
              text-base
              font-medium
              text-white
              hover:bg-indigo-700
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-red-500
              sm:ml-3 sm:w-auto sm:text-sm
            "
          >
            Guardar entrega
          </button>
          <button
            @click="hideModal()"
            type="button"
            class="
              mt-3
              w-full
              inline-flex
              justify-center
              rounded-md
              border border-gray-300
              shadow-sm
              px-4
              py-2
              bg-white
              text-base
              font-medium
              text-gray-700
              hover:bg-gray-50
              focus:outline-none
              focus:ring-2
              focus:ring-offset-2
              focus:ring-indigo-500
              sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm
            "
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import WLoading from "./WLoading.vue";
import WTextarea from "./WTextarea.vue";
export default {
  components: { WLoading, WTextarea },
  name: "WPickupConfirm",
  props: {
    value: {
      type: Object,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isValid() {
      return this.by && this.to;
    },
    buyer() {
      if (!this.value) return null;
      if (!this.value.shipping) return null;
      return [this.value.shipping.name, this.value.shipping.lastName].join(" ");
    },
    total() {
      if (!this.ids) return 0;
      return this.ids.length;
    },
    loader() {
      if (this.busy) return this.busy;
      return this.ids === null;
    },
  },
  data() {
    return {
      modify: null,
      to: null,
      by: null,
      notes: null,
      busy: false,
      groupName: "",
    };
  },
  mounted() {
    this.load();
  },
  watch: {
    value(buyer) {
      this.to = buyer;
      if (this.value.deliveredData) {
        this.by = this.value.deliveredData.by;
        this.notes = this.value.deliveredData.notes;
      }
    },
  },
  methods: {
    ...mapActions("shipments", {
      get: "get",
      patch: "patch",
    }),
    load() {
      console.log("buyer 2", this.buyer);
      this.to = this.buyer;
      if (this.value.deliveredData) {
        this.by = this.value.deliveredData.by;
        this.notes = this.value.deliveredData.notes;
      }
    },
    hideModal() {
      this.$emit("hide");
    },
    async save() {
      this.busy = "processing";
      await this.patch([
        this.value._id,
        {
          deliveredData: {
            by: this.by,
            to: this.to,
            notes: this.notes,
          },
          delivered: true,
          deliveredAt: new Date(),
        },
      ]);
      this.busy = false;
      // // REDIRECCIONAR CON EL FILTRO
      //   this.$router.push({
      //     name: "shipments",
      //     query: { group: this.groupName },
      //   });
      this.hideModal();
    },
    procesarCustomValues(value) {
      // Parsear objeto, retornar string
      if (typeof value === "object") {
        return Object.keys(value).map((key) => {
          return `${key}: ${value[key]}`;
        });
      } else return [];
    },
  },
  filters: {},
};
</script>
