<template>
  <div class="flex flex-col" :class="{ 'opacity-50': loading }">
    <div class="-my-2 overflow-x-auto md:overflow-hidden sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 overflow-hidden align-middle sm:px-6 lg:px-8">
        <div
          class="flex flex-col items-center justify-center h-48"
          v-if="items.length === 0 && !loading"
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              class="w-12 h-12 text-base-300"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div class="my-2 font-normal text-gray-700">No hay retiros</div>
        </div>
        <div v-else class="overflow-hidden shadow bshipment-b bshipment-gray-200 sm:rounded-lg">
          <table class="min-w-full overflow-hidden divide-y divide-gray-200 rounded-lg">
            <thead class="hidden md:table-header-group print:table-header-group">
              <tr>
                <th
                  class="py-3 px-6 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden md:table-cell bg-gray-50"
                >
                  Nombre
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase md:w-44 print:hidden md:table-cell bg-gray-50"
                >
                  Pedido
                </th>

                <th
                  class="w-32 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden bg-gray-50"
                >
                  Pago
                </th>
                <th
                  class="hidden px-6 py-3 text-xs text-right font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden md:table-cell bg-gray-50"
                >
                  Entrega
                </th>
              </tr>
            </thead>
            <tbody class="flex-1 bg-white divide-y divide-gray-300 sm:flex-none print:flex-none">
              <tr
                class="flex flex-col mb-2 flex-no wrap sm:table-row print:table-row sm:mb-0"
                v-for="shipment in items"
                :key="shipment._id"
              >
                <td class="flex py-3 overflow-hidden whitespace-no-wrap px-6">
                  <div class="">
                    <!-- <w-food-shipment-address :value="shipment.shipping" /> -->
                    <div v-if="shipment.shipping" class="print:w-auto">
                      <span class="text-xs italic text-gray-500"
                        >#{{ shipment.order.orderId }}</span
                      >
                      <span
                        class="px-1 ml-1 text-xs text-white bg-red-500 rounded"
                        v-if="shipment.prelaunch"
                        >Prelanzamiento</span
                      >
                      <div
                        v-if="shipment.shipping"
                        class="text-xs leading-5 text-gray-900 md:text-sm"
                      >
                        {{ shipment.shipping.name }}
                        {{ shipment.shipping.lastName }}
                      </div>
                      <div
                        v-else-if="shipment.shipping"
                        class="text-xs leading-5 text-gray-900 md:text-sm"
                      >
                        {{ shipment.shipping.name }}
                        {{ shipment.shipping.lastName }}
                      </div>
                      <div
                        v-else
                        class="text-xs italic font-medium leading-5 text-gray-400 md:text-sm"
                      >
                        Sin Nombre
                      </div>
                      <div class="text-xs leading-5 text-gray-500 md:text-sm">
                        {{ shipment.shipping.email }}
                      </div>
                      <div
                        v-if="shipment.shipping && shipment.shipping.phone"
                        class="flex items-center text-xs leading-5 text-gray-500 md:text-sm"
                      >
                        <div>
                          {{ shipment.shipping.phone.formatInternational }}
                        </div>
                        <a
                          v-bind:href="shipment.shipping.phone.formatInternational | whatsapp"
                          target="_blank"
                          ><svg
                            style="color: #25d366"
                            class="w-3 h-3 ml-1 cursor-pointer"
                            fill="currentColor"
                            role="img"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <title>WhatsApp icon</title>
                            <path
                              d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                    <div class="text-xs italic text-gray-500">
                      hace
                      {{ shipment.foodUpdated || shipment.createdAt | moment("from", true) }}
                    </div>
                  </div>
                </td>
                <td class="px-6 whitespace-no-wrap md:py-3">
                  <div class="text-xs">
                    <div class="" v-for="(it, itIndex) in shipment.items" :key="itIndex">
                      <div v-if="it.sku === '5fb99705f5a90f05cba6480c'">{{ it.qty }} x DRR</div>
                      <div v-else>{{ it.qty }} x {{ it.sku || it.name }}</div>
                      <w-item-variant :item="it" />
                    </div>
                    <w-shipment-custom-values :value="shipment.customValues" />
                    <div v-if="shipment.order.cart.findIndex(it => it.sku === 'COPA-AMERICA') === -1" class="text-xs">
                      <div :key="i" v-for="(it, i) in procesarApuestas(shipment.order.cart)">
                        1 x {{ it }} <i class="text-xs text-gray-500">- apuesta</i>
                      </div>
                    </div>
                  </div>
                </td>
                <td class="items-start text-xs whitespace-no-wrap px-5 pt-2 md:px-0 md:pt-0">
                  <w-order-payment-details :order="shipment.order" />
                  <w-discounts-detail :data="shipment.order.discounts"></w-discounts-detail>
                </td>
                <td class="px-6 py-4 text-sm font-medium leading-5 text-right whitespace-no-wrap">
                  <div v-if="!shipment.delivered" class="flex justify-end print:hidden">
                    <button
                      v-tooltip="'Archivar'"
                      v-if="!shipment.archive"
                      @click="$emit('deliver', shipment)"
                      class-old="flex items-center px-2 py-1 text-xs font-semibold text-white transition duration-150 ease-in-out bg-green-500 rounded hover:bg-green-700"
                      class="flex items-center text-xs font-semibold text-indigo-500 transition duration-150 ease-in-out"
                    >
                      <svg
                        version="1.1"
                        class="w-3 h-3 mr-1 text-indigo-500 fill-current"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 122.88 106.44"
                        style="enable-background: new 0 0 122.88 106.44"
                        xml:space="preserve"
                      >
                        <g>
                          <path
                            class="st0"
                            d="M41.22,96.1l-1.31-32.97l14.83-0.59c6.33,0.88,12.76,4.04,19.2,7.75l11.52-0.46c5.23,0.11,8.17,5.28,3.24,8.96 c-3.92,3.12-9.26,3.17-14.74,2.89c-3.77-0.04-3.74,5.03,0.19,4.9c1.37,0.05,2.84-0.33,4.14-0.38c6.82-0.28,12.38-1.8,15.6-7.33 l1.57-4.1l16.8-9.18c8.46-3.16,14.16,4.81,8.09,11.3c-12.05,9.52-23.73,18.21-36.51,24.73c-9.23,6.13-18.69,6.31-28.37,1.13 L41.22,96.1L41.22,96.1z M117.26,14.9l0.16,32.03c0.01,1.67-1.05,3.11-2.54,3.72l0,0.01L87.04,61.91 c-0.24,0.04-0.48,0.07-0.73,0.07c-0.54,0-1.07-0.11-1.57-0.32L57.34,51.18l0-0.01c-1.48-0.62-2.52-2.1-2.52-3.83l0.15-31.62 c-0.02-0.34,0-0.68,0.06-1.02c0.01-0.48,0.11-0.96,0.3-1.4c0.43-1.04,1.26-1.81,2.25-2.21l0,0L84.36,0.31 c1.09-0.44,2.26-0.39,3.26,0.04l26.73,9.67c1.6,0.58,2.61,2.02,2.68,3.57C117.18,14.01,117.26,14.45,117.26,14.9L117.26,14.9 L117.26,14.9z M81.06,55.25l0.2-27.9l-21.44-9.27L59.37,46.1L81.06,55.25L81.06,55.25L81.06,55.25z M112.68,18.09l-23.96,9.27 l0.19,27.87l23.91-8.93L112.68,18.09L112.68,18.09L112.68,18.09z M0,61.99l35.32-1.86l1.57,39.45l-35.32,1.85L0,61.99L0,61.99z"
                          />
                        </g>
                      </svg>

                      Entregar
                    </button>
                  </div>
                  <!-- Ya lo retiro -->
                  <div class="text-xs" v-else-if="shipment.deliveredData">
                    <div
                      v-if="shipment.deliveredAt"
                      class="text-gray-600 font-xs font-normal italic"
                    >
                      {{ shipment.deliveredAt | moment("hh:mm[hs] DD/MM/YY") }}
                    </div>
                    <div v-else class="text-gray-600 font-xs font-normal italic">
                      {{ shipment.updatedAt | moment("hh:mm[hs] DD/MM/YY") }}
                    </div>
                    Entregado por {{ shipment.deliveredData.by }} a
                    {{ shipment.deliveredData.to }}
                    <div
                      v-tooltip="shipment.deliveredData.notes"
                      class="text-indigo-500 cursor-pointer"
                      @click="$emit('deliver', shipment)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4 inline"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                        />
                      </svg>
                      Ver detalle
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    hide: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: () => {
        return null;
      },
    },
    totalResults: {
      type: Number,
      default: 0,
    },
    eventPrice: {
      type: Number,
      default: 0,
    },
    patchPending: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: [],
      selectAll: false,
      selectAllAll: false,
      showImage: null,
      upload: null,
      limit: 20,
      busy: false,
    };
  },
  methods: {
    async archive(shipment, status) {
      console.log("archive");
      this.busy = shipment._id;
      await this.$store.dispatch("shipments/patch", [shipment._id, { archive: status }, {}]);
      this.$emit("reload");
      this.busy = false;
    },
    procesarApuestas(cart) {
      const apuesta = cart.find((it) => it.apuesta);
      if (!apuesta) return [];
      // Apuesta es un objeto con las apuestas, y debo devoler un array con los 3 primeros
      const apuestasArray = Object.values(apuesta.apuesta);
      return apuestasArray.slice(0, 3);
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  bshipment-bottom: 0;
}

th:not(:last-child) {
  bshipment-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
.button-w-action {
  min-width: 150px;
}
</style>
